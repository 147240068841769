<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="项目名称">
              <a-select v-model="queryParam.projectId" @change="projectChange" show-search :filter-option="filterOption" size="small">
                <a-select-option v-for="(data, key) of allProject" :key="key" :value="data.id">{{ data.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="任务名称">
              <a-select :disabled="!queryParam.projectId" v-model="queryParam.taskId" @change="fileNameChange" show-search :filter-option="filterOption" size="small">
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="(data, key) of allTask" :key="key" :value="data.id">{{ data.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="座席人员">
              <a-select v-model="queryParam.userId" size="small">
                <a-select-option v-for="(data, key) of AllUser" :key="key" :value="data.id">{{ data.realName }}</a-select-option>
                <a-select-option :value="-1">无座席</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="外呼号码">
              <input type="file" accept=".xls,.xlsx" ref="upload" v-show="false" @input="submit" />
              <a-input-search v-model="queryParam.phones" size="small" enter-button="上传" @search="uploadPhones" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="呼出时间">
              <a-range-picker size="small" v-model="date" @change="onChange" />
            </a-form-item>
          </a-col>
          <!--          <a-col :md="8" :sm="24">-->
          <!--            <a-form-item label="通话时长">-->
          <!--              <a-input-group compact>-->
          <!--                <a-input style=" width: 100px; text-align: center" placeholder="最短时间" v-model="queryParam.minTime"/>-->
          <!--                <a-input-->
          <!--                  style=" width: 30px; border-left: 0; pointer-events: none; backgroundColor: #fff"-->
          <!--                  placeholder="~"-->
          <!--                  disabled-->
          <!--                />-->
          <!--                <a-input style="width: 100px; text-align: center; border-left: 0" placeholder="最长时间" v-model="queryParam.maxTime"/>-->
          <!--                <a-input-->
          <!--                  style=" width: 40px; border-left: 0; pointer-events: none; backgroundColor: #fff"-->
          <!--                  placeholder="秒"-->
          <!--                  disabled-->
          <!--                />-->
          <!--              </a-input-group>-->
          <!--            </a-form-item>-->
          <!--          </a-col>-->
          <a-col :md="6" :sm="24">
            <a-form-item label="呼叫状态">
              <a-select v-model="queryParam.callStatus" size="small">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="已接通">已接通</a-select-option>
                <a-select-option value="未接听">未接听</a-select-option>
                <!--                <a-select-option value="坐席无人接听">坐席无人接听</a-select-option>-->
                <!--                <a-select-option value="无可用坐席">无可用坐席</a-select-option>-->
                <!--                <a-select-option value="发起人取消">发起人取消</a-select-option>-->
                <a-select-option value="其他">其他</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="营销结果">
              <a-select v-model="queryParam.marketResultStatus" size="small">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="true">成功</a-select-option>
                <a-select-option value="false">失败</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="外呼小组">
              <a-select v-model="queryParam.organizationId" size="small">
                <a-select-option v-for="(data, key) of allOrganization" :key="key" :value="data.id">{{ data.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="queryClick" size="small">查询</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="reload" @click="clearClick" size="small">清空</a-button>
            </span>
          </a-col>
          <a-col :md="3" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" :loading="downLoad" size="small" icon="vertical-align-bottom" @click="exportClick" v-if="$checkPermission('Action:MarketMange:Export')">营销记录导出</a-button>
            </span>
          </a-col>
          <a-col :md="2" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" :loading="downLoad1" size="small" icon="vertical-align-bottom" @click="exportClick1" v-if="$checkPermission('Action:MarketCallManage:Export')">通话记录导出</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <a-table :columns="columns" :dataSource="data" rowKey="id" :loading="loading" :pagination="pagination" @change="pageChange">
      <!--      <span slot="time" slot-scope="time">-->
      <!--        {{ time | dateFormat }}-->
      <!--      </span>-->
      <span slot="all" slot-scope="text"> {{ text.marketResult }}--{{ text.outboundStatus | outboundStatus }} </span>
      <span slot="action" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            查看
          </template>
          <a-icon type="eye" theme="twoTone" @click="detailClick(text, '查看')" />
        </a-tooltip>
        <a-divider type="vertical" v-show="text.callStatus === '已接通'" />
        <a-tooltip>
          <template slot="title">
            编辑
          </template>
          <a-icon type="edit" theme="twoTone" @click="detailClick(text, '编辑')" v-show="text.callStatus === '已接通'" />
        </a-tooltip>
        <a-divider type="vertical" v-show="text.callStatus === '已接通'" />
        <a-tooltip>
          <template slot="title">
            播放录音
          </template>
          <a-icon type="play-circle" theme="twoTone" @click="playRadio(text)" v-show="text.callStatus === '已接通'" />
        </a-tooltip>
        <a-divider type="vertical" v-show="text.callStatus === '已接通'" />
        <a :href="text.record" download="" v-show="text.callStatus === '已接通'">
          <a-tooltip>
            <template slot="title">
              下载录音
            </template>
            <a-icon type="folder" theme="twoTone" />
          </a-tooltip>
        </a>
      </span>
    </a-table>
    <a-modal title="播放录音" :visible="visible" :footer="null" :closable="false" :centered="true" :destroyOnClose="true">
      <div class="dia-con">
        <audio :src="audioUrl" controls loop autoplay></audio>
        <p>
          <a-button @click="close">关闭</a-button>
        </p>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import moment from 'moment'
import { findAllExplicit, findAllOrganization, findAllProject, findAllTask, findAllTemplate, findAllUserList } from '@/api/findAll'
import { findCallRecord, exportExcel, exportZip } from '@/api/outbound/call'
import fileDownload from 'js-file-download'
import XLSX from 'xlsx'
const makeCols = refstr =>
  Array(XLSX.utils.decode_range(refstr).e.c + 1)
    .fill(0)
    .map((x, i) => ({ name: XLSX.utils.encode_col(i), key: i }))

const _SheetJSFT = ['xlsx', 'xlsb', 'xlsm', 'xls', 'xml', 'csv', 'txt', 'ods', 'fods', 'uos', 'sylk', 'dif', 'dbf', 'prn', 'qpw', '123', 'wb*', 'wq*', 'html', 'htm']
  .map(function(x) {
    return '.' + x
  })
  .join(',')
export default {
  name: 'ListMarket',
  data() {
    return {
      showEditModal: false,
      editData: {},
      pagination: {
        current: 1,
        total: 0,
        showTotal: function(total, range) {
          return '数据总数：' + total + ''
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '30', '50', '100', '200']
      },
      loading: false,
      // 查询参数
      queryParam: {
        page: 0,
        size: 10,
        projectId: null,
        taskId: null,
        phones: null,
        // minTime: null,
        // maxTime: null,
        startTime: null,
        endTime: null,
        marketResultStatus: null,
        callStatus: null,
        userId: null,
        organizationId: null,
        call: true
      },
      data: [],
      downLoad: false,
      downLoad1: false,
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'id'
        // },
        {
          title: '项目名称',
          dataIndex: 'projectName'
        },
        {
          title: '任务名称',
          dataIndex: 'taskName'
        },
        {
          title: '外呼号码',
          dataIndex: 'phone'
        },
        {
          title: '坐席人员',
          dataIndex: 'userName'
        },
        // {
        //   title: '外呼状态',
        //   dataIndex: 'callStatus'
        // },
        {
          title: '接听时间',
          dataIndex: 'answerTime'
        },
        {
          title: '挂断时间',
          dataIndex: 'hangupTime'
        },
        {
          title: '通话时长（秒）',
          dataIndex: 'talkTime'
        },
        {
          title: '营销结果',
          scopedSlots: { customRender: 'all' }
        },
        {
          title: '营销备注',
          dataIndex: 'marketRemark'
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 150,
          scopedSlots: { customRender: 'action' }
        }
      ],
      allProject: [],
      allOrganization: [],
      allExplicit: [],
      allTemplate: [],
      allTask: [],
      AllUser: [],
      date: [],
      cols: [
        { name: 'A', key: 0 },
        { name: 'B', key: 1 },
        { name: 'C', key: 2 },
        { name: 'D', key: 3 },
        { name: 'E', key: 4 },
        { name: 'F', key: 5 },
        { name: 'G', key: 6 }
      ],
      SheetJSFT: _SheetJSFT,
      visible: false,
      audioUrl: '',
      fileName: '全部任务'
    }
  },
  filters: {
    dateFormat(date) {
      if (date) {
        let format = 'yyyy-MM-dd HH:mm:ss'
        date = new Date(date)
        date.setHours(date.getHours() - 14)
        const o = {
          'M+': date.getMonth() + 1, // month
          'd+': date.getDate(), // day
          'H+': date.getHours(), // hour
          'm+': date.getMinutes(), // minute
          's+': date.getSeconds(), // second
          'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
          S: date.getMilliseconds() // millisecond
        }
        if (/(y+)/.test(format)) {
          format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
        }
        for (const k in o) {
          if (new RegExp('(' + k + ')').test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
          }
        }
        return format
      }
    },
    outboundStatus(value) {
      if (value === 'SUCCESS') {
        return '成功'
      } else if (value === 'FAIL') {
        return '失败'
      } else {
        return ''
      }
    }
  },
  created() {
    this.findAll()
  },
  activated() {
    // this.findAll()
    this.query()
  },
  methods: {
    moment,
    async findAll() {
      await findAllProject()
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.allProject = res.body
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
      await findAllOrganization()
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.allOrganization = res.body
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
      await findAllExplicit()
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.allExplicit = res.body
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
      await findAllTemplate()
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.allTemplate = res.body
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
      await findAllTask()
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.allTask = res.body
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
      await findAllUserList()
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.AllUser = res.body
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    uploadPhones() {
      this.$refs.upload.click()
    },
    submit(e) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        const bstr = e.target.result
        const wb = XLSX.read(bstr, { type: 'binary' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 })
        const arr = []
        data.slice(1).forEach(v => {
          arr.push(v[0])
        })
        this.queryParam.phones = arr.join(',')
        this.cols = makeCols(ws['!ref'])
      }
      reader.readAsBinaryString(file)
    },
    onChange(date, dateString) {
      this.queryParam.startTime = dateString[0]
      this.queryParam.endTime = dateString[1]
    },
    cancelHandle() {
      this.showEditModal = false
      this.editData = {}
    },
    editHandle() {
      this.cancelHandle()
      this.query()
    },
    detailClick(row, type) {
      // this.editData = { ...JSON.parse(JSON.stringify(row)), ...{ type } }
      // this.showEditModal = true
      const data = { taskId: this.queryParam.taskId, ...row }
      this.$emit('cut', 'EditMarket', type, data)
    },
    addUserClick() {
      this.showEditModal = true
    },
    pageChange(pageNumber) {
      this.pagination.current = pageNumber.current
      this.queryParam.page = pageNumber.current - 1
      this.queryParam.size = pageNumber.pageSize
      this.query()
    },
    queryClick() {
      this.pagination.current = 1
      this.queryParam.page = 0
      this.query()
    },
    clearClick() {
      this.queryParam = this.$options.data().queryParam
      this.date = []
      this.$refs.upload.value = ''
    },
    projectChange(value) {
      findAllTask({ projectId: value })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.allTask = res.body
          this.queryParam.taskId = null
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
    },
    fileNameChange(value) {
      const index = this.allTask.findIndex(data => {
        return value === data.id
      })
      if (index === -1) {
        this.fileName = '全部任务'
      } else {
        this.fileName = this.allTask[index].name
      }
    },
    exportClick() {
      const data = { ...this.queryParam }
      delete data.size
      delete data.page
      if (data.phones) {
        data.phones = data.phones.split(',')
      } else {
        data.phones = null
      }
      this.downLoad = true
      exportExcel(data)
        .then(res => {
          if (res.type === 'application/json') {
            const reader = new FileReader()
            reader.readAsText(res, 'utf-8')
            reader.onload = e => {
              const status = JSON.parse(reader.result)
              if (status.status !== 200) {
                this.$notification.error({
                  message: '提示',
                  description: status.message
                })
              }
            }
          } else {
            fileDownload(res, `${this.fileName}.xlsx`)
          }
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
        .finally(() => {
          this.downLoad = false
        })
    },
    exportClick1() {
      const data = { ...this.queryParam }
      delete data.size
      delete data.page
      if (data.phones) {
        data.phones = data.phones.split(',')
      } else {
        data.phones = null
      }
      this.downLoad1 = true
      exportZip(data)
        .then(res => {
          if (res.type === 'application/json') {
            const reader = new FileReader()
            reader.readAsText(res, 'utf-8')
            reader.onload = e => {
              const status = JSON.parse(reader.result)
              if (status.status !== 200) {
                this.$notification.error({
                  message: '提示',
                  description: status.message
                })
              }
            }
          } else {
            fileDownload(res, `${this.fileName}.zip`)
          }
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
        .finally(() => {
          this.downLoad1 = false
        })
    },
    query() {
      this.loading = true
      const data = { ...this.queryParam }
      if (data.phones) {
        data.phones = data.phones.split(',')
      } else {
        data.phones = null
      }
      findCallRecord(data)
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          this.data = res.body.body
          this.pagination.total = res.body.total
        })
        .catch(e => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    playRadio(value) {
      this.audioUrl = value.record
      this.visible = true
    },
    close() {
      this.visible = false
    }
  }
}
</script>

<style scoped>
.table-page-search-wrapper .ant-row {
  margin-bottom: 5px;
}
.dia-con {
  text-align: center;
}
.dia-con p {
  margin-top: 30px;
}
</style>
