<template>
  <keep-alive include="ListMarket">
    <component :is="component" @cut="cut" :title="title" :editData="editData" ref="com"></component>
  </keep-alive>
</template>

<script>
import EditMarket from '@/views/outbound/market/children/EditMarket'
import ListMarket from '@/views/outbound/market/children/ListMarket'
export default {
  name: 'MarketView',
  components: {
    EditMarket,
    ListMarket
  },
  data () {
    return {
      component: 'ListMarket',
      editData: '',
      title: ''
    }
  },
  watch: {
    component (n, o) {
      if (n === 'ListMarket' && o === 'EditMarket') {
        this.$nextTick(() => {
          this.$refs.com.query()
        })
      }
    }
  },
  methods: {
    cut (name, type, data) {
      this.editData = data
      this.title = type
      this.component = name
    }
  }
}
</script>

<style scoped>

</style>
