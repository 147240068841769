<template>
  <a-card :bordered="false">
    <p class="title">用户信息</p>
    <div class="box">
      <div class="box-child">
        <a-row :gutter="48" v-if="show">
          <a-col :md="24" :sm="24" v-for="(d,k) of dynamicData.customData" :key="k" class="col">
            {{ k }}：{{ d }}
          </a-col>
        </a-row>
        <div v-html="editor" class="view"></div>
      </div>
      <a-divider type="vertical" />
      <div class="box-child">
        <p>营销状态</p>
        <div class="type-box">
          <div v-for="(d,k) of tableData" :key="k" class="type-table">
            <div class="type-params" v-if="d.childFiled.length!==0">
              {{ d.name }}
            </div>
            <a-radio-group class="type-params" name="id" v-model="form.fieldId" v-else>
              <a-radio :value="d.id">{{ d.name }}-{{ d.type | type }}</a-radio>
            </a-radio-group>
            <a-radio-group name="id" v-model="form.fieldId">
              <a-radio v-for="(c,k) of d.childFiled" :key="k" :value="c.id">{{ c.name }}-{{ c.type | type }}</a-radio>
            </a-radio-group>
          </div>
        </div>
        <p>备注</p>
        <a-textarea
          v-model="form.remark"
          :rows="10"
        />
        <div class="footer">
          <a-button @click="editHandleOk" v-if="title!=='查看'">确定</a-button>
          <a-button @click="back">取消</a-button>
        </div>
      </div>
    </div>
  </a-card>
</template>

<script>

import { detail, update } from '@/api/outbound/market'
export default {
  name: 'EditMarket',
  props: {
    editData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dynamicData: {},
      insideData: {},
      tableData: [],
      value: '',
      form: {
        fieldId: '',
        id: this.editData.id,
        remark: ''
        // taskId: this.editData.taskId
      },
      editor: '',
      show: false
    }
  },
  filters: {
    type (value) {
      if (value === 'SUCCESS') {
        return '成功'
      } else if (value === 'FAIL') {
        return '失败'
      } else {
        return ''
      }
    }
  },
  mounted () {
    this.detail()
  },
  methods: {
    detail () {
      const data = { taskId: this.editData.taskId, id: this.editData.id }
      detail(data).then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        // const pattern = new RegExp('[\u4E00-\u9FA5]+')
        // let pattern2 = new RegExp("[A-Za-z]+");
        const data = res.body
        this.dynamicData = data.record
        // for (const i in data) {
        //   if (pattern.test(i)) {
        //     this.dynamicData[i] = data[i]
        //   } else {
        //     this.insideData[i] = data[i]
        //   }
        // }
        this.form.remark = data.record.marketRemark
        this.tableData = data.marketStatus.fields.sort((o1, o2) => {
          return o1.id - o2.id
        }).map((n) => {
          if (n.childFiled.length !== 0) {
            n.childFiled.sort((o1, o2) => {
              return o1.id - o2.id
            })
          }
          return n
        })
        this.form.fieldId = data.record.marketResultId
        this.editor = data.talkSkill.talkSkill
        this.show = true
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      }).finally(() => {
        this.loading = false
      })
    },
    editHandleOk () {
      update(this.form).then(res => {
        if (res.status !== 200) {
          this.$notification.error({
            message: '提示',
            description: res.message
          })
          return
        }
        this.$emit('cut', 'ListMarket')
        this.$notification.success({
          message: '提示',
          description: res.message
        })
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      }).finally(() => {
        this.confirmLoading = false
      })
    },
    back () {
      this.$emit('cut', 'ListMarket')
    }
  }
}
</script>

<style scoped>
  .title{
  font-size: 30px;
  text-align: center;
}
  .box{
    display: flex;
  }
  .box-child{
    width: 49%;
  }
  .col{
    margin: 10px 0;
  }
  .type-box{
    border: 1px solid #f0f2f5;
    padding: 20px;
  }
  .type-table{
    display: flex;
  }
  .type-params{
    border-right: 1px solid #f0f2f5;
    width: auto;
  }
  .type-table>div{
  box-sizing: border-box;
  padding: 10px;
  border-bottom: 1px solid #f0f2f5;
}
  .footer{
    text-align: center;
    margin-top: 30px;
  }
  .footer button{
    margin: 0 30px;
  }
  .view {
    border: 1px solid #ddd;
    padding: 10px 0;
  }
  /deep/.ant-radio-wrapper:hover{
    color: #1690ff;
  }
  .type-box .ant-radio-wrapper-checked {
    color: #1690ff;
    font-weight: bold;
  }
</style>
<style lang="less">
  .view {
    word-wrap: break-word;
    word-break: normal;
    .selectTdClass {
      background-color: #edf5fa !important
    }

    table.noBorderTable td, table.noBorderTable th, table.noBorderTable caption {
      border: 1px dashed #ddd !important
    }

    table {
      margin-bottom: 10px;
      border-collapse: collapse;
      display: table;
    }

    td, th {
      padding: 5px 10px;
      border: 1px solid #DDD;
    }

    caption {
      border: 1px dashed #DDD;
      border-bottom: 0;
      padding: 3px;
      text-align: center;
    }

    th {
      border-top: 1px solid #BBB;
      background-color: #F7F7F7;
    }

    table tr.firstRow th {
      border-top-width: 2px;
    }

    .ue-table-interlace-color-single {
      background-color: #fcfcfc;
    }

    .ue-table-interlace-color-double {
      background-color: #f7faff;
    }

    td p {
      margin: 0;
      padding: 0;
    }
  }
</style>
