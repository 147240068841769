import { axios } from '@/utils/request'
const basic = '/manage/call'
const api = {
  // 分页查询通话记录

  findCallRecord: basic + '/findCallRecord',
  // 导出excel
  exportExcel: basic + '/exportExcel',
  // 导出zip
  exportZip: basic + '/exportZip'
}
export function findCallRecord (data) {
  return axios({
    url: api.findCallRecord,
    method: 'post',
    data: data
  })
}
export function exportExcel (data) {
  return axios({
    url: api.exportExcel,
    method: 'post',
    responseType: 'blob',
    data: data,
    timeout: 0
  })
}
export function exportZip (data) {
  return axios({
    url: api.exportZip,
    method: 'post',
    responseType: 'blob',
    data: data,
    timeout: 0
  })
}
