import { axios } from '@/utils/request'
const basic = '/manage/market'
const api = {
  // 营销记录查看详情
  detail: basic + '/detail',
  // 营销记录修改
  update: basic + '/update',
  // 电话接入页面营销记录添加
  updateMarket: basic + '/updateMarket',
  indexDetail: '/manage/index/detail'
}
export function indexDetail(parameter) {
  return axios({
    url: api.indexDetail,
    method: 'post',
    params: parameter,
    // 将超时时间缩短，并启用重试机制
    timeout: 1500
  })
}
export function detail(parameter) {
  return axios({
    url: api.detail,
    method: 'post',
    params: parameter
  })
}
export function update(parameter) {
  return axios({
    url: api.update,
    method: 'post',
    params: parameter
  })
}
export function updateMarket(parameter) {
  return axios({
    url: api.updateMarket,
    method: 'post',
    params: parameter
  })
}
